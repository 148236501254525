<template>
  <div class="bw has-navbar-vertical-aside navbar-vertical-aside-show-xl footer-offset">
    <NavbarHeader v-model="showSidebar" :showMenu="showMenu" />
    <Sidebar :showSidebar="showSidebar" :sidebarListItem="sidebarListItem" />
    <main id="content" role="main" class="main">
      <div class="content container-fluid">
        <router-view :key="$route.fullPath" />
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import getUserAgentDetails from "@/utils/getUserAgentDetails";
import NavbarHeader from "@/components/manager/NavbarHeader.vue";
import Sidebar from "@/components/manager/Sidebar.vue";
import { checkAuth, checkAuthOperator } from "@/utils/checkAuth";

export default {
  name: "PanelManager",
  components: {
    NavbarHeader,
    Sidebar
  },

  data() {
    return {
      userAgentDetails: {},
      showSidebar: false,
      showMenu: false,

      sidebarListItem: [
        {
          type: "span",
          title: "manager_dashboard"
        },
        {
          title: "dashboard",
          icon: "bi-house ",
          url: {
            type: "name",
            url: "PanelManager-Home"
          }
        },

        /*
        {
          title: "devices",
          icon: "bi-ethernet ",
          url: {
            type: "name",
            url: "PanelManager-Devices",
          },
        },
        {
          title: "zones",
          icon: "bi-geo-fill ",
          url: {
            type: "name",
            url: "PanelManager-Zones",
          },
        },

        {
          title: "events",
          icon: " bi-clipboard-plus",
          url: {
            type: "name",
            url: "PanelManager-Events",
          },
        },
        */

        {
          title: "events",
          icon: " bi-clipboard-plus",
          url: {
            type: "name",
            url: "PanelManager-Events"
          }
        },


        {
          title: "اساتید",
          icon: " bi-clipboard-plus",
          url: {
            type: "name",
            url: "PanelManager-Teachers"
          }
        },

        {
          title: "کتاب صوتی",
          icon: " bi-clipboard-plus",
          url: {
            type: "name",
            url: "PanelManager-Audiobook-List"
          }
        },

        {
          title: "ثبت نامی",
          icon: " bi-person-plus",
          url: {
            type: "name",
            url: "PanelManager-EventRegistered"
          }
        },

        {
          title: "تقویم",
          icon: " bi-calendar-date",
          url: {
            type: "name",
            url: "PanelManager-Calender"
          }
        },

        {
          title: "نقشه فناوری",
          icon: " bi-pin",
          url: {
            type: "name",
            url: "PanelManager-Qsciencemap"
          }
        },
        {
          title: "blog",
          icon: " bi-body-text",
          url: {
            type: "name",
            url: "PanelManager-BlogList"
          }
        },

        {
          title: "پادکست",
          icon: " bi-mic",
          url: {
            type: "name",
            url: "PanelManager-Podcast-List"
          }
        },

        {
          title: "ویدیو",
          icon: " bi-film",
          url: {
            type: "name",
            url: "PanelManager-Video-List"
          }
        },

        {
          title: "تراکنش ها",
          icon: " bi-clipboard-plus",
          url: {
            type: "name",
            url: "PanelManager-Transactions"
          }
        },


        {
          title: "لغت نامه",
          icon: " bi-arrow-return-right",
          url: {
            type: "name",
            url: "PanelManager-Dictionary"
          }
        },


        {
          title: "داستان کوانتوم",
          icon: " bi-arrow-return-left",
          url: {
            type: "name",
            url: "PanelManager-Story"
          }
        },
        {
          title: "تایم لاین",
          icon: "bi-clock",
          url: {
            type: "name",
            url: "PanelManager-TimeLine"
          }
        },
        {
          title: "نویسنده ها",

          icon: " bi-pencil",
          url: {
            type: "name",
            url: "PanelManager-Writer"
          }
        },

        {
          title: "بازی ها",

          icon: "bi-controller",
          url: {
            type: "name",
            url: "PanelManager-Games"
          }
        },

        {
          title: "وبینار",

          icon: "bi-file-earmark-play",
          url: {
            type: "name",
            url: "PanelManager-Webinar"
          }
        },

        {
          title: "users",
          icon: "bi-people",
          url: {
            type: "name",
            url: "PanelManager-Users"
          }
        },

        {
          title: "مدیران",
          icon: "bi-people",
          url: {
            type: "name",
            url: "PanelManager-Managers"
          }
        },

        {
          title: "upload_center",
          icon: " bi-cloud-upload",
          url: {
            type: "name",
            url: "PanelManager-Uploadcenter"
          }
        },
        {
          title: "کدتخفیف",
          icon: " bi-tag",
          url: {
            type: "name",
            url: "PanelManager-Discounts"
          }
        },

        {
          title: "دوره ها",
          icon: " bi-cast",
          url: {
            type: "name",
            url: "PanelManager-Courses"
          }
        },


        {
          title: "تراکنش دوره ها",
          icon: " bi-cast",
          url: {
            type: "name",
            url: "PanelManager-TransactionsCourses"
          }
        },

        {
          title: "همکاری",
          icon: " bi-mortarboard",
          url: {
            type: "name",
            url: "PanelManager-Cooperation"
          }
        },

        // {
        //   title: "analytics",
        //   icon: "bi-graph-up-arrow",
        //   url: {
        //     type: "name",
        //     url: "PanelManager-analytics",
        //   },
        // },

        /*
        {
          title: "monitoring",
          icon: "bi-display",
          url: {
            type: "name",
            url: "PanelManager-Monitoring",
          },
        },

       

        {
          title: "history",
          icon: "bi-clock-history ",
          url: {
            type: "name",
            url: "PanelManager-History-Index",
          },
        },

        {
          title: "setting",
          icon: "bi-gear ",
          url: {
            type: "name",
            url: "PanelManager-Preferences-Index",
          },
        },
        */

        {
          type: "span",
          title: "user"
        },

        /*
        {
          title: "operators",
          icon: "bi-person ",
          url: {
            type: "name",
            url: "PanelManager-Managers",
          },
        },
        

        {
          title: "managers",
          icon: "bi-person ",
          url: {
            type: "name",
            url: "PanelManager-Managers",
          },
        },
*/
        {
          title: "sign_out",
          icon: "bi-box-arrow-right ",
          url: {
            type: "name",
            url: "PanelManager-Logout"
          }
        }
      ]
    };
  },
  methods: {
    sleep: function(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    HandelGetUserAgentDetails() {
      this.userAgentDetails = getUserAgentDetails();
    }
  },
  watch: {
    "userAgentDetails.screenWidth": {
      handler() {
        if (this.userAgentDetails.screenWidth < 1200) {
          this.showMenu = true;
        } else {
          this.showMenu = false;
          this.showSidebar = false;
        }
      }
    },

    $route() {
      if (
        this.$route.meta.requiresAuth &&
        this.$store.state.managerRoutesGroupName
      ) {
        if (!this.$store.state.managerIsAuthenticated) {
          this.$store.commit("clearManagerToken");
          this.$router.push({
            name: `${this.$store.state.managerRoutesGroupName}-Login`
          });
        }
      }
    }
  },

  async created() {
    window.addEventListener("resize", this.HandelGetUserAgentDetails);
    this.HandelGetUserAgentDetails();
  },
  destroyed() {
    window.removeEventListener("resize", this.HandelGetUserAgentDetails);
  },

  mounted() {}
};
</script>

<style>
/**
  * Persian Number: Vazirmatn FD
  * English Number: Vazirmatn
  */
.invalid-feedback {
  display: unset !important;
}

.list-checked-item::before {
  background-image: unset !important;
}

html {
  -moz-transition: left 1s ease 0.1s, width 0s ease-out 0.1s,
    border-width 0s ease-out 0.1s;
  -webkit-transition: left 1s ease 0.1s, width 0s ease-out 0.1s,
    border-width 0s ease-out 0.1s;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.nav-link dropdown-toggle.router-link-exact-active {
  color: red;
}

.nav-link dropdown-toggle.router-link-exact-active:hover {
  color: green;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
td,
tr,
code,
strong,
small,
span,
button,
label {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.select2-container--default {
  display: block;
  width: 100%;
  padding: 0.6125rem 2.25rem 0.6125rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #677788;
  background-color: #fff;

  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 13px 9px;
  border: 0.0625rem solid rgba(231, 234, 243, 0.7);
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select2-container--default .select2-selection--single {
  border: unset;
}

.select2-search__field {
  color: #1e2022;
  border: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
  border-radius: 0.3125rem;
}

.select2-dropdown select2-dropdown--below {
  background-color: #fff;
  border: 0.0625rem solid rgba(231, 234, 243, 0.7);
  border-radius: 0.3125rem;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
}

.nav .nav-tabs li {
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slither-enter-active,
.slither-leave-active {
  transition: transform 1s;
}

.slither-enter,
.slither-leave-to {
  transform: translateX(-100%);
}

.slither-enter-to,
.slither-leave {
  transform: translateX(0);
}

.drain-enter-active,
.drain-leave-active {
  transition: transform 1s;
}

.drain-enter,
.drain-leave-to {
  transform: translateY(100%);
}

.drain-enter-to,
.drain-leave {
  transform: translateY(0);
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d1cece;
}
</style>
